"use client";

import React, { PropsWithChildren } from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { useAppTheme } from "@/utils/hooks/useAppTheme";
import clsx from "classnames";

import ThemeWrapper from "@/components/Theme/ThemeWrapper";

import { queryClient } from "@/utils/queryClient";

const RootLayoutWrapper = ({ children }: PropsWithChildren) => {
  const { theme: appTheme } = useAppTheme();
  return (
    <QueryClientProvider client={queryClient}>
      <body
        className={clsx({
          "dark-content": appTheme === "dark",
        })}
      >
        <ThemeWrapper>{children}</ThemeWrapper>
        <Toaster />
        <ReactQueryDevtools initialIsOpen={false} />
      </body>
    </QueryClientProvider>
  );
};

export default RootLayoutWrapper;
